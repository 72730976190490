import { Center, Image, Stack, Text } from "@mantine/core";
import { FC } from "react";

interface EmptyStateMessageProps {
  imageSrc: string;
  message?: string;
}

export const EmptyStateMessage: FC<EmptyStateMessageProps> = ({ imageSrc, message }) => {
  return (
    <Stack mt="xl">
      <Center>
        <Image src={imageSrc} alt={message} miw="300" w="20%" maw="100%" />
      </Center>

      {message && (
        <Text c="dimmed" fw={600} ta="center" size="xl">
          {message}
        </Text>
      )}
    </Stack>
  );
};
