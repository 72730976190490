"use client";

import { Button, Container, Stack } from "@mantine/core";
import * as Sentry from "@sentry/nextjs";
import { IconChevronLeft } from "@tabler/icons-react";
import { useRouter } from "next/navigation";
import { FC, useEffect } from "react";
import { EmptyStateMessage } from "~/lib/components/EmptyStateMessage";

interface Props {
  error: Error;
  digest?: string;
}

const GlobalError: FC<Props> = ({ error }) => {
  const router = useRouter();

  // eslint-disable-next-line use-encapsulation/prefer-custom-hooks
  useEffect(() => {
    Sentry.captureException(error);
  }, [error]);

  return (
    <html>
      <body>
        return (
        <Container h="100vh">
          <Stack gap="xl" align="center">
            <EmptyStateMessage imageSrc="/empty-state/47.svg" message="Bir hata oluştu" />

            <Button leftSection={<IconChevronLeft />} onClick={() => router.back()}>
              Geri Dön
            </Button>
          </Stack>
        </Container>
        );
      </body>
    </html>
  );
};

export default GlobalError;
